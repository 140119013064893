<template>
	<div class="find pa-3">
		
		<div class="mt-10 text-gray">
			등록된 이메일 주소로 아이디 찾기
		</div>
		
		<div class="text-caption mt-2 text-gray">
			가입당시 등록한 이메일 주소를 입력해주세요
		</div>
		
		<div class="mt-10">
		
			<v-text-field
				v-model="item.businessName"
				label="상점명"
				placeholder="가입시 등록한 상점명을 입력하세요"
				outlined
				color="success2"
				hide-detail
				:error="error ? true : false"
				maxlength="50"
			></v-text-field>

			<v-text-field
				v-model="item.phoneNumber"
				label="휴대폰 번호"
				placeholder="가입시 등록한 휴대폰 번호를 입력하세요"
				outlined
				color="success2"
				hide-detail
				type="number"
				:error="error ? true : false"
				:error-messages="error ? '입력하신 정보와 매칭되는 아이디가 없습니다' : ''"
				:rules="[rules.max]"
			></v-text-field>
			
		</div>
		
		<v-btn 
			class="mt-5"
			color="success2" 
			@click="find"
			block
			:disabled="!item.phoneNumber || !item.businessName ? true : false"
		>
			확인
		</v-btn>
		
		<div class="text-center mt-3 text-caption text-gray">
			<router-link 
				:to="'/Login'" 
				class="text-decoration-none router-link"
			>로그인
			</router-link>
			|
			<router-link 
				:to="'/Help/FindPw'" 
				class="text-decoration-none router-link"
			>비밀번호 찾기
			</router-link>
			|
			<router-link 
				:to="'/Help/Agree'" 
				class="text-decoration-none router-link"
			>회원가입
			</router-link>
		</div>
	</div>
</template>
<script>

	export default{
		name: 'FindId'
		,created: function(){
			this.$emit('setProgram', this.program, this.options)
		}
		,props: ['callBack']
		,data: function(){
			return{
				program: {
					title: '아이디 찾기'
					,not_navigation: true
					,prev: {
						to: '/Login'
					}
				}
				,options: {
				}
				,item: {
					businessName: ''
					, phoneNumber: ''
				}
				,error: false
				,sample: {
					email: 'coehdus@naver.com'
				}
				,rules: {
					max: value => {
						let max = 15
						if (value.length > max) {
							this.item.phoneNumber = this.item.phoneNumber.substring(0, max)
							return false
						} else {
							return true
						}
					}
				}
			}
		}
		,methods: {
			go: function(type){
				if(type == 'id'){
					this.$router.push('/Help/FindId')
				}else{
					this.$router.push('/Help/FindPw')
				}
			}
			,find: function(){
				this.$emit('axios',{
					request_type: 'post'
					, request_url: '/find/users/id'
					, request_params: this.item
					,callBack: 'result'
				})
			}
			,result: function(call){
				
				if(call.result){
					console.log('modal')
					this.error = false
					this.$emit('modal', {
						title: '아이디 찾기'
						,contents: call.items.message
						,to: '/Login'
					})
				}else{
					this.error = true
				}
			}
		}
		,watch: {
			callBack: function(call){
				if(call.name == 'result'){
					this.result(call)
				}
			}
		}
	}
</script>
<style>
	.find {}
	.find a {color: gray !important; padding: 0;}
	.find .v-text-field input {font-size: 0.8em; color: black;}
	.find .v-label {font-size: 1.2em !important; font-weight: bold; color: gray; }

	.find .v-input legend {width: 50px !important;}
	
	.v-text-field__slot {color: gray !important;}
</style>